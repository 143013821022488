import { Link } from 'react-router-dom';
import { ReactComponent as LogoLight } from '../../Static/logos/logo.svg';
import { ReactComponent as LogoDark } from '../../Static/logos/logo-dark.svg';

import { StyledLogo } from './styled';

export const Logo = ({ color = 'light' }) => {
  return (
    <StyledLogo className="logo" as={Link} to="/">
      {color === 'light' ? <LogoLight /> : <LogoDark />}
    </StyledLogo>
  );
};
