import { Step1 } from './Step1';
import { StyledContainerMd, StyledContainerXxs } from '../UI/containers';
import {
  StyledModalDescription,
  StyledModalTitleXL,
} from '../UI/Components/Modal/styled';
import { useMemo, useState } from 'react';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Form } from '../UI/Components/Form';
import { StyledButton } from '../UI/buttons';
import { StyledSteps, StyledStepsItem } from './styled';
import { validation } from '../../Utils/validation';
import { sendMail } from '../../Configs/emailConfig';
import { emailConfig } from '../../Configs/emailConfig';
import { useLang } from '../../Hooks/useLang';
import L from './locale.json';
import generalL from './../../Utils/generalLocale.json';
import { openModal } from '../../Redux/Actions/ModalActions';
import { useDispatch, useSelector } from 'react-redux';
import { getLang } from '../../Redux/Selectors/language';
import { phoneCodeConfig } from '../../Configs/phoneCodeConfig';

const conf = [
  ['name', 'surname', 'phone', 'email'],
  ['companyName'],
  ['city', 'country'],
];

const initialState = {
  name: '',
  surname: '',
  email: '',
  phone: '',
  companyName: '',
  scopeActivity: '',
  numberEmployees: '',
  city: '',
  country: '',
  message: '',
};

const initialStateErrors = {
  name: null,
  surname: null,
  email: null,
  phone: null,
  companyName: null,
  scopeActivity: null,
  city: null,
  country: null,
};

const { early_adopter_program_template_id } = emailConfig;

export const EarlyAdopterProgram = () => {
  const {
    title,
    description,
    buttonNext,
    buttonSubmit,
    placeholders,
    successSendTitle,
    hints,
  } = useLang(L);
  const { errorSend } = useLang(generalL);
  const [inputs, setInputs] = useState(initialState);
  const [errors, setErrors] = useState(initialStateErrors);
  const [policy, setPolicy] = useState(true);
  const [step, setStep] = useState(0);
  const lengthValidation = useSelector(getLang);

  const dispatch = useDispatch();

  const disabledStep = useMemo(() => {
    return conf[step].some((name) => errors[name] || errors[name] === null);
  }, [step, errors]);

  const checkStep = (index) => {
    let classes = [];

    if (index === step) {
      classes.push('is-use');
    }

    if (index < step || (index === step && !disabledStep)) {
      classes.push('is-ready');
    } else if (index === step || (index === step + 1 && !disabledStep)) {
      classes.push('is-enabled');
    }

    return classes;
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const { value, error } = validation(event.target, lengthValidation);

    setInputs((values) => {
      return {
        ...values,
        [name]: value,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        [name]: error,
      };
    });
  };

  const switchStep = (step, event) => {
    event.preventDefault();
    setStep(step);
  };

  const handlePolicy = (e) => {
    setPolicy(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      ...inputs,
      phone: `${phoneCodeConfig + inputs.phone}`,
    };

    sendMail(early_adopter_program_template_id, data)
      .then(({ status, text }) => {
        dispatch(
          openModal({
            Comp: 'modalMessage',
            data: {
              title: status === 200 ? successSendTitle : text,
            },
            preserve: false,
          })
        );
      })
      .catch((error) => {
        dispatch(
          openModal({
            Comp: 'modalMessage',
            data: {
              message: errorSend,
            },
            preserve: false,
          })
        );
        console.error(error.text);
      });
  };

  return (
    <>
      <StyledContainerMd>
        <StyledSteps>
          <StyledStepsItem
            className={[...checkStep(0)]}
            onClick={(event) => switchStep(0, event)}
          >
            1
          </StyledStepsItem>
          <StyledStepsItem
            className={[...checkStep(1)]}
            onClick={(event) => switchStep(1, event)}
          >
            2
          </StyledStepsItem>
          <StyledStepsItem
            className={[...checkStep(2)]}
            onClick={(event) => switchStep(2, event)}
          >
            3
          </StyledStepsItem>
        </StyledSteps>
        <StyledModalTitleXL>{title}</StyledModalTitleXL>
        <StyledModalDescription>{description}</StyledModalDescription>
        <StyledContainerXxs>
          <Form>
            {step === 0 && (
              <Step1
                placeholders={placeholders}
                hints={hints}
                errors={errors}
                inputs={inputs}
                handleChange={handleChange}
              />
            )}
            {step === 1 && (
              <Step2
                placeholders={placeholders}
                hints={hints}
                errors={errors}
                inputs={inputs}
                handleChange={handleChange}
              />
            )}
            {step === 2 && (
              <Step3
                placeholders={placeholders}
                hints={hints}
                errors={errors}
                inputs={inputs}
                policy={policy}
                handlePolicy={handlePolicy}
                handleChange={handleChange}
              />
            )}

            {step === 2 ? (
              <StyledButton
                disabled={disabledStep || !policy}
                onClick={handleSubmit}
              >
                {buttonSubmit}
              </StyledButton>
            ) : (
              <StyledButton
                disabled={disabledStep}
                onClick={(event) => switchStep(step + 1, event)}
              >
                {buttonNext}
              </StyledButton>
            )}
          </Form>
        </StyledContainerXxs>
      </StyledContainerMd>
    </>
  );
};
