import styled from 'styled-components';
import S from './variables';

export const StyledContainer = styled.div`
  padding: 0 ${S.spaces.sm};
  margin: 0 auto;
  width: 100%;

  & & {
    padding: 0;
  }

  @media (min-width: ${S.breakpoints.xs}) {
    padding: 0 ${S.spaces.lg};
  }

  @media (min-width: ${S.breakpoints.df}) {
    padding: 0 ${S.spaces.sm};
  }
`;

export const StyledContainerFull = styled(StyledContainer)`
  max-width: 100%;
`;

export const StyledContainerXxs = styled(StyledContainer)`
  @media (min-width: ${S.breakpoints.md}) {
    padding: 0 ${S.spaces.sm};
    max-width: calc(${S.containers.xxs} + ${S.spaces.sm} * 2);
  }
`;

export const StyledContainerXs = styled(StyledContainer)`
  max-width: calc(${S.containers.xs} + ${S.spaces.sm} * 2);
`;

export const StyledContainerSm = styled(StyledContainer)`
  max-width: calc(${S.containers.sm} + ${S.spaces.sm} * 2);
`;

export const StyledContainerMd = styled(StyledContainer)`
  max-width: calc(${S.containers.md} + ${S.spaces.sm} * 2);
`;

export const StyledContainerDf = styled(StyledContainer)`
  max-width: calc(${S.containers.df} + ${S.spaces.sm} * 2);
`;

export const StyledContainerLg = styled(StyledContainer)`
  max-width: calc(${S.containers.lg} + ${S.spaces.sm} * 2);

  @media (min-width: ${S.breakpoints.xs}) {
    max-width: calc(${S.containers.lg} + ${S.spaces.lg} * 2);
  }

  @media (min-width: ${S.breakpoints.df}) {
    max-width: calc(${S.containers.lg} + ${S.spaces.sm} * 2);
  }
`;
