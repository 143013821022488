import { parsers } from '../Configs/parsers';
import L from '../Utils/generalLocale.json';

export const validation = (input, lang) => {
  const { validation: LangError } = L[lang];

  const { type, value: v, required, name } = input;

  const parse = parsers[name] || ((v) => v);

  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const value = parse(v);

  // const isValue = (value) => value.length > 0 && true;

  const isEmail = (value) => !!String(value).toLowerCase().match(regexEmail);

  const isPhone = (value) => /^(\w{8,9})$/.test(value);

  let error = '';

  if (v !== value) {
    error = LangError.format;

    return { value, error };
  }

  if (required && !value.length) {
    error = LangError.required;
  } else {
    switch (type) {
      case 'email':
        error = !isEmail(value) && LangError.format;
        break;
      case 'tel':
        error = !isPhone(value) && LangError.phone;
        break;
      default:
        error = false;
    }
  }

  return { value, error };
};
