import {
  StyledFormError,
  StyledFormInput,
  StyledFormTelephone,
} from '../styled';
import { StyledFormTelephoneLabel } from '../styled';

export const FormInputTelephone = ({
  error,
  type = 'tel',
  name,
  placeholder,
  required,
  onChange,
  value,
}) => {
  return (
    <>
      <StyledFormTelephone>
        <StyledFormTelephoneLabel>+370</StyledFormTelephoneLabel>
        <StyledFormInput
          className={error ? 'has-error' : ''}
          type={type}
          name={name}
          placeholder={placeholder}
          required={required}
          onChange={onChange}
          value={value}
        />
      </StyledFormTelephone>
      <StyledFormError>{error && error}</StyledFormError>
    </>
  );
};
