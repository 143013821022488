import { StyledFormError, StyledFormHint, StyledFormInput } from '../styled';

export const FormInput = ({
  hint,
  error,
  type,
  name,
  placeholder,
  required,
  value,
  onChange,
}) => {
  const classes = [error ? 'has-error' : '', hint ? 'has-hint' : ''];

  return (
    <>
      <StyledFormInput
        className={[...classes]}
        type={type}
        name={name}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        value={value}
      />
      <StyledFormError>{error && error}</StyledFormError>
      {hint && (
        <StyledFormHint>
          <div>{hint}</div>
        </StyledFormHint>
      )}
    </>
  );
};
