import styled from 'styled-components';
import S from '../UI/variables';

export const StyledFaqItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
    border-bottom: 1px solid ${S.colors.gray2};
  }
`;

export const StyledFaqItemBody = styled.div`
  overflow: hidden;
  transition: max-height ${S.transitions.transform};
`;

export const StyledFaqItemText = styled.p`
  padding: 0 0 16px 0;
  font-size: 16px;
  line-height: 20px;
`;

export const StyledFaqItemHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 16px;
  transition: color ${S.transitions.color};

  &:hover {
    color: ${S.colors.purple};
  }
`;

export const StyledFaqItemArrow = styled.svg`
  fill: currentColor;
  margin-top: 4px;
  transition: transform ${S.transitions.transform};
  flex-shrink: 0;

  ${StyledFaqItem}.is-open & {
    transform: scale(-1);
  }
`;

export const StyledFaqItemTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding-right: 12px;
`;
