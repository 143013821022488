export const parsers = {
  phone: (v) => (v.match(/\d+/g) || []).join(''),
  vin: (v) =>
    (v.match(/\w+/g) || [])
      .map((char) => char.toUpperCase())
      .join('')
      .slice(0, 17),
  number: (v) =>
    (v.match(/\w+/g) || [])
      .map((char) => char.toUpperCase())
      .join('')
      .slice(0, 6),
};
