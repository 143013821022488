import { StyledAuthBtns } from './styled';
import { useLang } from '../../Hooks/useLang';
import L from './locale.json';
import { BASE_URL } from '../../Configs/constants';

export const AuthBtns = () => {
  const { signUp, signIn } = useLang(L);

  return (
    <StyledAuthBtns>
      <a href={`${BASE_URL}/registration`} target="_blank" rel="noreferrer">
        {signUp}
      </a>
      <a href={`${BASE_URL}/login`} target="_blank" rel="noreferrer">
        {signIn}
      </a>
    </StyledAuthBtns>
  );
};
