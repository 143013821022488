import { NavbarHeader } from '../NavbarHeader';
import { StyledBurgerButton, StyledHeader, StyledHeaderInner } from './styled';
import { Logo } from '../Logo';
import { LanguagePicker } from '../LanguagePicker';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Burger } from '../../Static/svg/common/burger.svg';
import { MobilePanel } from '../MobilePanel';
import { AuthBtns } from '../AuthBtns';

export const Header = () => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const header = useRef(null);

  const handleBurger = () => {
    setOpen(!open);
  };

  const handleScroll = () => {
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const headerHeight = header.current.clientHeight;

    if (scroll >= headerHeight) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    open
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style = '');

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [open]);

  return (
    <>
      <StyledHeader as="header" ref={header} isActive={active}>
        <MobilePanel open={open} onClose={() => setOpen(!open)} />
        <StyledHeaderInner>
          <Logo />
          <NavbarHeader isActive={active} />
          <LanguagePicker />
          <AuthBtns />
          <StyledBurgerButton
            className={open && 'is-open'}
            onClick={handleBurger}
          >
            <Burger />
          </StyledBurgerButton>
        </StyledHeaderInner>
      </StyledHeader>
    </>
  );
};
