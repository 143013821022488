import styled from 'styled-components';

import S from '../../variables';
import { rgba } from 'polished';

// Styled Popup
export const StyledModalPopupFull = styled.div`
  align-self: flex-start;
  position: relative;
  min-height: calc(100% + 32px);
  min-width: calc(100% + 32px);
  background-color: ${S.colors.gray};
  overflow: auto;
  padding: 105px 0 106px;
  margin: -16px;

  & > span {
    display: none;

    @media (min-width: ${S.breakpoints.df}) {
      display: block;
    }

    svg {
      position: fixed;
      z-index: 0;

      &:first-child {
        @media (min-width: ${S.breakpoints.df}) {
          left: 120px;
          bottom: 80px;
        }

        @media (min-width: ${S.breakpoints.lg}) {
          left: 197px;
          bottom: 137px;
        }
      }

      &:last-child {
        @media (min-width: ${S.breakpoints.df}) {
          right: 180px;
          top: 200px;
        }

        @media (min-width: ${S.breakpoints.lg}) {
          right: 280px;
          top: 232px;
        }
      }
    }
  }

  @media (min-width: ${S.breakpoints.lg}) {
  }

  @media (min-width: ${S.breakpoints.md}) {
    margin: -24px;
    min-height: calc(100% + 48px);
    min-width: calc(100% + 48px);
  }

  @media (min-width: ${S.breakpoints.df}) {
    margin: -36px;
    min-height: calc(100% + 72px);
    min-width: calc(100% + 72px);
  }
`;

export const StyledModalPopupXs = styled.div`
  margin: auto;
  width: 100%;
  max-width: 485px;
  padding: 16px 16px 32px;
  background-color: ${S.colors.white};
  border-radius: 15px;
  transition: transform ${S.transitions.transform};
`;

// modal Titles
export const StyledModalTitleXL = styled.h2`
  font-size: 36px;
  line-height: 43px;
  color: ${S.colors.green};
  margin-bottom: 16px;
  font-weight: 700;
  text-align: center;

  @media (min-width: ${S.breakpoints.md}) {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const StyledModalTitleMD = styled.h2`
  font-size: 36px;
  line-height: 34px;
  color: ${S.colors.green};
  margin-bottom: 24px;
  font-weight: 700;
  text-align: center;
`;

export const StyledModalTitleXS = styled.h2`
  font-size: 24px;
  line-height: 28px;
  color: ${S.colors.green};
  margin-bottom: 24px;
  font-weight: 600;
  text-align: center;
`;

export const StyledModalDescription = styled.h2`
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 36px;
  }

  ${StyledModalPopupXs} & {
    font-size: 16px;
    line-height: 20px;
  }

  @media (min-width: ${S.breakpoints.md}) {
    font-size: 24px;
    line-height: 34px;

    ${StyledModalPopupXs} & {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

// modal Headers
export const StyledModalHeaderFull = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 8px 0;
  background-color: ${S.colors.gray};

  @media (min-width: ${S.breakpoints.md}) {
    padding: 12px 0;
  }

  @media (min-width: ${S.breakpoints.df}) {
    padding: 24px 0;
  }

  & > div {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledModalHeaderXs = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  padding: 4px 0;
`;

export const StyledModalClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  cursor: pointer;
  transition:
    transform ${S.transitions.transform},
    background-color ${S.transitions.background};

  &:hover {
    transform: scale(1.1);
  }
`;

export const StyledModalCloseFull = styled(StyledModalClose)`
  width: 48px;
  height: 48px;
  background-color: ${S.colors.gray2};
  border-radius: 50%;

  @media (min-width: ${S.breakpoints.md}) {
    width: 56px;
    height: 56px;
  }

  &:hover {
    background-color: ${rgba(S.colors.gray2, 0.8)};
  }
`;

export const StyledModalWrapper = styled.div`
  opacity: ${({ opened }) => (opened ? 1 : 0)};
  backdrop-filter: blur(${({ opened }) => (opened ? '11px' : 0)});
  pointer-events: ${({ opened }) => (opened ? 'auto' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;
  height: 100%;
  overflow: auto;
  transform-origin: center center;
  background: ${rgba(S.colors.gray, 0.8)};
  transition:
    opacity ${S.transitions.transform},
    backdrop-filter ${S.transitions.transform},
    transform ${S.transitions.transform};

  @media (min-width: ${S.breakpoints.md}) {
    padding: 24px;
  }

  @media (min-width: ${S.breakpoints.df}) {
    padding: 36px;
  }

  // & {
  //     transform: scale(${({ opened }) => (opened ? 1 : 0)});
  // }
`;
