import { PopupXs } from '../PopupXs';
import { StyledModalDescription, StyledModalTitleXS } from '../styled';

export const ModalMessage = ({ onClose, title = '', message }) => {
  return (
    <>
      <PopupXs onClose={onClose}>
        <StyledModalTitleXS>{title}</StyledModalTitleXS>
        {message ? (
          <StyledModalDescription>{message}</StyledModalDescription>
        ) : null}
      </PopupXs>
    </>
  );
};
