import styled from 'styled-components';
import S from '../UI/variables';
import { rgba } from 'polished';

export const StyledSteps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledStepsItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${S.colors.gray2};
  cursor: pointer;
  pointer-events: none;
  transition:
    color ${S.transitions.color},
    background-color ${S.transitions.transform},
    box-shadow ${S.transitions.transform};

  &.is-ready {
    pointer-events: auto;
    background-color: ${S.colors.greenLight};
    color: ${rgba(S.colors.greenLight, 0)};

    &::after {
      transform: scale(1);
    }
  }

  &.is-enabled {
    pointer-events: auto;
    background-color: ${S.colors.purple};
  }

  &:not(:last-child):hover {
    box-shadow: 0 0 0 2px ${S.colors.purpleDark};
  }

  &.is-use {
    pointer-events: none;
  }

  &:not(:last-child) {
    margin-right: 12px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transition: transform ${S.transitions.transform};
    transform: scale(0);
    background: ${S.colors.greenLight}
      url("data:image/svg+xml,%3Csvg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 3.4L5.25 7L11.5 1' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A")
      no-repeat center center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;
