import styled from 'styled-components';
import S from '../UI/variables';

export const StyledAuthBtns = styled.div`
  display: flex;
  align-items: center;
  background-color: ${S.colors.greenLight};
  border-radius: 15px;
  line-height: 1;
  margin-left: 15px;
  overflow: hidden;
  font-size: 12px;

  &:hover {
    a {
      &::before {
        opacity: 0;
      }
    }
  }

  a {
    position: relative;
    text-decoration: none;
    color: ${S.colors.black};
    font-weight: 600;
    transition:
      color ${S.transitions.color},
      background-color ${S.transitions.background},
      opacity ${S.transitions.background};
    max-width: 110px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      background-color: ${S.colors.green};
      color: ${S.colors.white};
    }

    &:first-child {
      padding: 13px 12px 13px 16px;

      &::before {
        content: '';
        position: absolute;
        top: 11px;
        bottom: 11px;
        width: 3px;
        right: 0;
        border-radius: 5px;
        background-color: #96c42a;
        transition: opacity ${S.transitions.background};
      }
    }

    &:last-child {
      padding: 13px 16px 13px 12px;
    }
  }
`;
