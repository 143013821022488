import { createGlobalStyle } from 'styled-components';
import S from './variables';

export const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        font-family: ${({ lang }) =>
          lang === 'ru' ? S.fontFamily.defaultRu : S.fontFamily.default};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: ${S.colors.green};
        background-color: ${S.colors.white};
        font-size: 16px;
        font-weight: 400;
        min-width: 320px;

        @media(hover: hover) {
            &::-webkit-scrollbar {
                width: 12px;
                height: 12px;
            }

            &::-webkit-scrollbar-track {
                background-color: ${S.colors.white};
                border-radius: 4px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 6px;
                border: 4px solid #fff;
                background-color: ${S.colors.green};
                cursor: pointer;
            }

            & *::-webkit-scrollbar {
                width: 12px;
                height: 12px;
            }
            & *::-webkit-scrollbar-track {
                background-color: #fff;
                border-radius: 4px;
            }

            & *::-webkit-scrollbar-thumb {
                border-radius: 6px;
                border: 4px solid #fff;
                background-color: ${S.colors.green};
                cursor: pointer;
            }
        }
    }

    .link {
        color: ${S.colors.purple};
        transition: color ${S.transitions.color};
        font-weight: 600;

        &:hover {
            color: ${S.colors.purpleDark};
        }
    }

    .page {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 1920px;
        min-height: 100vh;
        width: 100%;

        &__body {
          flex: 1;
        }
    }

    .swiper-lazy {
        transition: opacity ${S.transitions.transform};
        opacity: 0;

        &-loaded {
            opacity: 1;
        }
    }

    .swiper-pagination {
        display: flex;
        justify-content: center;
        padding-top: 48px;

        @media(max-width: ${S.breakpoints.df}) {
            padding-top: 16px;
        }

        &-bullet {
            width: 8px;
            height: 8px;
            background-color: ${S.colors.gray4};
            border-radius: 50%;
            transition: background-color ${S.transitions.background};

            &:not(:last-child) {
                margin-right: 12px;
            }

            &-active {
                background-color: ${S.colors.purple};
            }
        }
    }

    #root {
        @media print {
            & header, & footer, & aside {
                display: none;
            }
        }
    }

    *,
    ::after,
    ::before {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }

    :root {
        -moz-tab-size: 4;
        tab-size: 4;
    }
    a {
        text-decoration: none;
    }
    html {
        line-height: 1.2;
        -webkit-text-size-adjust: 100%;
    }
    hr {
        height: 0;
    }
    abbr[title] {
        text-decoration: underline dotted;
    }
    b,
    strong {
        font-weight: bolder;
    }
    code,
    kbd,
    pre,
    samp {
        //font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
        font-size: 1em;
    }
    small {
        font-size: 80%;
    }
    sub,
    sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }
    sub {
        bottom: -0.25em;
    }
    sup {
        top: -0.5em;
    }
    button,
    input,
    optgroup,
    select,
    textarea {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
        user-select: auto;
    }
    button,
    select {
        text-transform: none;
    }
    [type='button'],
    [type='reset'],
    [type='submit'],
    button {
        -webkit-appearance: button;
    }
    fieldset {
        padding: 0.35em 0.75em 0.625em;
    }
    legend {
        padding: 0;
    }
    progress {
        vertical-align: baseline;
    }
    [type='number']::-webkit-inner-spin-button,
    [type='number']::-webkit-outer-spin-button {
        height: auto;
    }
    [type='search'] {
        -webkit-appearance: textfield;
        outline-offset: -2px;
    }
    [type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }
    ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit;
    }
    summary {
        display: list-item;
    }
    button {
        outline: none;
        background: none;
        border: none;
    }
    ul {
        list-style-type: none;
    }
    input {
        user-select: text;
    }
`;
