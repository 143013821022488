import styled from 'styled-components';
import S from '../UI/variables';

export const StyledFooter = styled.div`
  padding: 16px 0;
  font-size: 12px;
  line-height: 14px;
  color: ${S.colors.green};

  @media (min-width: ${S.breakpoints.md}) {
    font-size: 14px;
    line-height: 18px;
    padding: 32px 0;
  }
`;

export const StyledFooterGrid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: ${S.breakpoints.df}) {
    flex-wrap: nowrap;
  }
`;

export const StyledFooterSocials = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledFooterSocialsItem = styled.a`
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 10px;
  background-color: ${S.colors.gray};
  color: ${S.colors.green};
  font-weight: 600;
  line-height: initial;
  transition:
    color ${S.transitions.color},
    box-shadow ${S.transitions.transform};

  @media (hover: hover) {
    &:hover {
      color: ${S.colors.purple};
      box-shadow: 0 0 0 2px ${S.colors.purple};
    }
  }

  svg {
    margin-right: 8px;
    flex-shrink: 0;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const StyledFooterTerms = styled.div`
  display: flex;
`;

export const StyledFooterTermsItem = styled.div`
  display: block;
  cursor: pointer;
  color: ${S.colors.green};
  transition: color ${S.transitions.color};

  &:not(:last-child) {
    margin-right: 12px;
  }

  &:visited {
    color: ${S.colors.green};
  }

  &:hover {
    color: ${S.colors.purple};
  }
`;

export const StyledFooterGridItem = styled.div`
  @media (max-width: ${S.breakpoints.md}) {
    &:nth-child(4) {
      ${StyledFooterSocialsItem} {
        background-color: transparent;
        padding: 0;

        &:not(:last-child) {
          margin-right: 16px;
        }

        svg {
          margin: 0;
          width: 36px;
          height: 36px;
        }

        span {
          @media (max-width: ${S.breakpoints.md}) {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 1023px) {
    &:nth-child(1) {
      order: 3;
      margin-top: 24px;
    }

    &:nth-child(2) {
      order: 1;
    }

    &:nth-child(3) {
      order: 3;
      margin-top: 24px;
      margin-left: 60px;
      margin-right: auto;

      @media (min-width: ${S.breakpoints.md}) {
        margin-left: auto;
        margin-right: 0;
      }
    }

    &:nth-child(4) {
      order: 2;
      width: calc(100% - 160px);

      ${StyledFooterSocials} {
        margin-left: auto;
        width: max-content;
      }
    }
  }

  @media (max-width: ${S.breakpoints.xxs}) {
    &:nth-child(3) {
      margin-left: 36px;
    }
  }

  @media (min-width: ${S.breakpoints.df}) {
    &:nth-child(1) {
      order: 1;
    }

    &:nth-child(2) {
      order: 2;
    }

    &:nth-child(3) {
      order: 3;
    }

    &:nth-child(4) {
      order: 4;
    }
  }
`;
