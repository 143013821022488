import styled from 'styled-components';

export const StyledAbsoluteCentering = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
`;
