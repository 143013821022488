import { routes } from '../Routes';

export const socialsConfig = {
  partners: {
    navLink: routes[1].path,
    text: 'Link',
    href:
      document.location.protocol +
      '//' +
      document.location.host +
      '/' +
      routes[1].path,
  },
  instagram: {
    text: 'Instagram',
    href: 'https://www.instagram.com/fairfix.lithuania',
  },
  facebook: {
    text: 'Facebook',
    href: 'https://www.facebook.com/fairfix.lithuania',
  },
  linkedIn: {
    text: 'LinkedIn',
    href: 'https://www.linkedin.com/company/fairfix-lithuania',
  },
  mail: {
    text: 'info@fairfix.lt',
    href: 'mailto:info@fairfix.lt',
  },
};
