import { Header } from './Components/Header';
import { Footer } from './Components/Footer';
import { Route, Routes, useLocation } from 'react-router';
import { routes } from './Routes';
import { WithTitle } from './HOC/withTitle';
import { GlobalStyle } from './Components/UI/globalStyles';
import { Modal } from './Components/UI/Components/Modal';
import { useSelector } from 'react-redux';
import { getLang } from './Redux/Selectors/language';
import { NotFound } from './Pages/Error';
import { hotjar } from 'react-hotjar';
import { ENV } from './Configs/constants';
import { useEffect } from 'react';

const filteredRoutes = routes.filter(({ Component }) => !!Component);

const HotJarManager = () => {
  const location = useLocation();
  useEffect(() => {
    if (ENV === 'production') {
      hotjar.initialize({ id: 3734974, sv: 6, debug: true });
    }
  }, []);

  useEffect(() => {
    if (hotjar.initialized()) {
      hotjar.stateChange(location.pathname.replace(/^\//, ''));
    }
  }, [location.pathname]);

  return null;
};

function App() {
  const lang = useSelector(getLang);

  return (
    <>
      <HotJarManager />
      <Modal />
      <div className="page">
        <GlobalStyle lang={lang} />
        <Header />
        <div className="page__body">
          <Routes>
            <Route path="*" element={<NotFound />} />
            {filteredRoutes.map(({ linkName, Component, path }, i) => {
              return (
                <Route
                  key={i}
                  path={path}
                  element={
                    <WithTitle linkName={linkName}>
                      <Component />
                    </WithTitle>
                  }
                />
              );
            })}
          </Routes>
        </div>
        <div className="page__footer">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
