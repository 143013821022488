import { StyledFormCheckBox } from '../styled';

export const FormCheckBox = ({ children, name, checked, onChange }) => {
  return (
    <StyledFormCheckBox>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <span>{children}</span>
    </StyledFormCheckBox>
  );
};
