import { StyledFormError, StyledFormTextarea } from '../styled';

export const FormTextarea = ({
  error,
  name,
  placeholder,
  required,
  value,
  onChange,
}) => {
  return (
    <>
      <StyledFormTextarea
        as="textarea"
        name={name}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        value={value}
      />
      <StyledFormError>{error && error}</StyledFormError>
    </>
  );
};
