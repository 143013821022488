import { useRef, useState, useEffect, useCallback, memo } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { closeModal } from '../../../../Redux/Actions/ModalActions';

import { StyledModalWrapper } from './styled';

import { ModalSubmitYourApp } from './ModalSubmitYourApp';
import { ModalFaq } from './ModalFaq';
import { ModalMessage } from './ModalMessage';

const components = {
  modalSubmitYourApp: ModalSubmitYourApp,
  modalFaq: ModalFaq,
  modalMessage: ModalMessage,
};

export const Modal = memo(() => {
  const { data = {}, Comp, preserve } = useSelector(({ modal }) => modal);

  const [Component, setComponent] = useState(null);
  const [elementData, setElementData] = useState({});

  const dispatch = useDispatch();

  const timeoutRef = useRef(null);

  const close = useCallback(
    () => dispatch(closeModal({ preserve })),
    [dispatch, preserve]
  );
  const handleClose = (e) => e.target === e.currentTarget && close();

  const handleKeyDown = useCallback(
    (e) => e.key === 'Escape' && close(),
    [close]
  );

  useEffect(() => {
    if (Comp) {
      document.addEventListener('keydown', handleKeyDown, false);
      document.body.style.overflow = 'hidden';
      setComponent(Comp);
      setElementData(data);
    } else {
      clearTimeout(timeoutRef.current);
      document.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = '';
      if (!preserve && Component) {
        timeoutRef.current = setTimeout(() => {
          setComponent(null);
          setElementData({});
        }, 1000);
      }
    }
  }, [
    Comp,
    Component,
    data,
    handleKeyDown,
    preserve,
    setComponent,
    setElementData,
  ]);

  const Element = components[Component];

  return (
    <StyledModalWrapper opened={Comp in components} onClick={handleClose}>
      {Component ? (
        <Element
          {...elementData}
          onClose={close}
          isOpen={Component in components}
        />
      ) : null}
    </StyledModalWrapper>
  );
});
