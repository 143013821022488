import { Suspense } from 'react';
import { Loading } from '../Components/Loading';

export const WithSuspense = (Component) => {
  return (props) => {
    return (
      <Suspense fallback={<Loading />}>
        <Component {...props} />
      </Suspense>
    );
  };
};
