import { FormGroup } from '../../UI/Components/Form/FormGroup';
import { FormInput } from '../../UI/Components/Form/FormInput';
import { FormTextarea } from '../../UI/Components/Form/FormTextarea';
import { FormCheckBox } from '../../UI/Components/Form/FormCheckBox';

export const Step3 = ({
  errors,
  placeholders,
  policy,
  handlePolicy,
  inputs,
  handleChange,
}) => {
  return (
    <>
      <FormGroup>
        <FormInput
          name="city"
          placeholder={placeholders.city}
          type="text"
          required
          error={errors.city}
          value={inputs.city || ''}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <FormInput
          name="country"
          placeholder={placeholders.country}
          type="text"
          required
          error={errors.country}
          value={inputs.country || ''}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <FormTextarea
          name="message"
          placeholder={placeholders.message}
          error={errors.message}
          value={inputs.message || ''}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <FormCheckBox
          name="policy"
          checked={policy}
          required
          onChange={handlePolicy}
        >
          {placeholders.policy}
        </FormCheckBox>
      </FormGroup>
    </>
  );
};
