import styled, { css } from 'styled-components';
import S from '../UI/variables';
import { StyledAuthBtns } from '../AuthBtns/styled';

export const StyledMobilePanel = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 2;
  top: 0;
  left: 0;
  padding: 100px 16px 56px;
  width: 100%;
  height: 100%;
  transition: ${S.transitions.transform};
  background-color: ${S.colors.green};
  transform: translateX(100%);
  border-radius: 50px;
  visibility: hidden;

  & ${StyledAuthBtns} {
    display: flex;
    position: relative;
    width: max-content;
    margin: 0;
  }

  @media (min-width: ${S.breakpoints.lg}) {
    display: none;
  }

  ${({ open }) =>
    open &&
    css`
      transform: translateX(0);
      border-radius: 0;
      opacity: 1;
      visibility: visible;
    `}
`;

export const StyledMobilePanelScroll = styled.div`
  overflow: auto;
  flex: 1;
`;

export const StyledMobilePanelFooter = styled.div`
  display: flex;
  padding: 24px 0 0;
`;

export const StyledMobilePanelBg = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
`;
