import styled from 'styled-components';
import S from '../UI/variables';
import { StyledAbsoluteCentering } from '../UI/helpers';
import { StyledContainer } from '../UI/containers';
import { StyledButton } from '../UI/buttons';
import { Form } from '../UI/Components/Form';

export const StyledHeaderBanner = styled.div`
  position: relative;
  overflow: hidden;
`;

export const StyledHeaderBannerInner = styled(StyledContainer)`
  position: relative;
  z-index: 1;
  max-width: calc(834px + ${S.spaces.sm} * 2);
  padding: 130px 16px 130px;

  @media (min-width: ${S.breakpoints.md}) {
    padding: 120px 48px 130px;
  }

  @media (min-width: ${S.breakpoints.df}) {
    padding: 231px 16px 160px;
  }
`;

export const StyledHeaderBannerText = styled.h1`
  font-size: 45px;
  line-height: 104.4%;
  font-family: ${S.fontFamily.additional};
  color: ${S.colors.white};
  background-color: rgba(255, 255, 255, 0.2);
  width: fit-content;
  margin: 0 auto;
  border-radius: 18px;
  backdrop-filter: blur(10px);
  padding: 16px;
  text-align: center;

  @media (min-width: ${S.breakpoints.md}) {
    font-size: 48px;
  }

  @media (min-width: ${S.breakpoints.df}) {
    font-size: 64px;
  }
`;

export const StyledHeaderBannerButton = styled(StyledButton)`
  margin: 24px auto 0;
  display: block;
  width: fit-content;
`;

export const StyledHeaderBannerRatio = styled.div`
  position: relative;
  min-height: 630px;

  @media (min-width: ${S.breakpoints.md}) {
    min-height: 400px;
  }

  @media (min-width: ${S.breakpoints.df}) {
    min-height: 615px;
  }
`;

export const StyledHeaderBannerImg = styled(StyledAbsoluteCentering)`
  object-fit: cover;
  height: 100%;
  width: 100%;
  filter: ${({ isError }) => (isError ? 'grayscale(1)' : 'none')};
`;

export const StyledForm = styled(Form)`
  width: 100%;
  max-width: 340px;
  margin: 16px auto 0;
  padding: 8px;
  border-radius: 18px;
  background-color: ${S.colors.purple};
`;

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const StyledOr = styled.p`
  font-size: 24px;
  color: ${S.colors.white};
  background-color: ${S.colors.purple};
  border-radius: 8px;
  padding: 8px 16px;
  text-transform: uppercase;
`;

export const StyledPhoneIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  left: 16px;
  top: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${S.colors.purple};
`;

export const StyledPhoneLink = styled.a`
  position: relative;
  display: flex;
  height: 56px;
  border-radius: 15px;
  background-color: ${S.colors.white};
  font-size: 24px;
  font-weight: bold;
  color: ${S.colors.purple};
  padding: 0 16px;
  &:hover {
    color: ${S.colors.purpleDark};
    & > ${StyledPhoneIcon} {
      background-color: ${S.colors.purpleDark};
    }
  }
  & > span {
    width: 100%;
    margin: auto;
    text-align: center;
  }
`;
