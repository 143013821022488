import { NavLink } from 'react-router-dom';
import { routes } from '../../Routes';
import {
  StyledNavbarPanel,
  StyledNavbarPanelList,
  StyledNavbarPanelListItem,
  StyledNavbarPanelListLink,
} from './styled';
import { useLang } from '../../Hooks/useLang';
import L from '../NavbarHeader/locale.json';

export const NavbarPanel = ({ onClose }) => {
  const locale = useLang(L);

  return (
    <StyledNavbarPanel>
      <StyledNavbarPanelList>
        {routes.map(({ path, linkName, skipNav, hash }, i) => {
          return skipNav ? null : (
            <StyledNavbarPanelListItem key={i}>
              <StyledNavbarPanelListLink
                as={NavLink}
                onClick={onClose}
                to={path + (hash || '')}
                className={hash ? 'is-anchor' : ''}
              >
                {locale.links[linkName]}
              </StyledNavbarPanelListLink>
            </StyledNavbarPanelListItem>
          );
        })}
      </StyledNavbarPanelList>
    </StyledNavbarPanel>
  );
};
