import { FaqItem } from './FaqItem';
import { useState } from 'react';
import { useLang } from '../../Hooks/useLang';
import L from './locale.json';

export const Faq = () => {
  const { items } = useLang(L);
  const [active, setActive] = useState(null);

  const handleCollapse = (indexItem) => {
    active === indexItem ? setActive(null) : setActive(indexItem);
  };

  return items.length
    ? items.map(({ question, answer }, i) => {
        return (
          <FaqItem
            key={i}
            indexItem={i}
            activeItem={active}
            onOpen={() => handleCollapse(i)}
            question={question}
            answer={answer}
          />
        );
      })
    : null;
};
