import { NavLink } from 'react-router-dom';
import { routes } from '../../Routes';
import {
  StyledNavbar,
  StyledNavbarList,
  StyledNavbarListItem,
  StyledNavbarListLink,
} from './styled';
import { useLang } from '../../Hooks/useLang';
import L from './locale.json';
import { useLocation } from 'react-router';

export const NavbarHeader = () => {
  const locale = useLang(L);

  const location = useLocation();

  return (
    <StyledNavbar>
      <StyledNavbarList>
        {routes.map(({ path, linkName, hash, skipNav }, i) => {
          return skipNav ? null : (
            <StyledNavbarListItem key={i}>
              <StyledNavbarListLink
                as={NavLink}
                to={path + (hash || '')}
                className={
                  location.hash
                    ? location.hash === hash
                      ? 'is-active'
                      : ''
                    : location.pathname === path && !hash
                    ? 'is-active'
                    : ''
                }
              >
                {locale.links[linkName]}
              </StyledNavbarListLink>
            </StyledNavbarListItem>
          );
        })}
      </StyledNavbarList>
    </StyledNavbar>
  );
};
