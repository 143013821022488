import { forwardRef } from 'react';
import { StyledForm } from './styled';

export const Form = forwardRef((props, ref) => {
  return (
    <>
      <StyledForm ref={ref} {...props}>
        {props.children}
      </StyledForm>
    </>
  );
});
