import { createReducer } from '@reduxjs/toolkit';

import { openModal, closeModal } from '../../Actions/ModalActions';

const initialState = {
  data: null,
  Comp: null,
  preserve: false,
};

const modal = createReducer(initialState, {
  [openModal]: (state, action) => ({ ...initialState, ...action.payload }),
  [closeModal]: (state, action) => ({ ...initialState, ...action.payload }),
});

export default modal;
