import { createReducer } from '@reduxjs/toolkit';
import { setLanguage } from '../../Actions/langeugesActions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { langs } from '../../../Configs/constants';

const prevLang = window.navigator.languages[0];

const initialState = {
  lang: langs.find((lang) => prevLang.startsWith(lang)) || 'en',
};

const reducer = createReducer(initialState, {
  [setLanguage]: (state, action) => ({
    lang: action.payload,
  }),
});

const persistConfig = {
  key: 'lang',
  storage,
};

export default persistReducer(persistConfig, reducer);
