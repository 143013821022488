import { FormGroup } from '../../UI/Components/Form/FormGroup';
import { FormInput } from '../../UI/Components/Form/FormInput';

export const Step2 = ({
  hints,
  errors,
  placeholders,
  inputs,
  handleChange,
}) => {
  return (
    <>
      <FormGroup>
        <FormInput
          name="companyName"
          placeholder={placeholders.companyName}
          type="text"
          required
          error={errors.companyName}
          value={inputs.companyName || ''}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <FormInput
          name="scopeActivity"
          placeholder={placeholders.scopeActivity}
          type="text"
          hint={hints.scopeActivity}
          error={errors.scopeActivity}
          value={inputs.scopeActivity || ''}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <FormInput
          name="numberEmployees"
          placeholder={placeholders.numberEmployees}
          type="text"
          error={errors.numberEmployees}
          value={inputs.numberEmployees || ''}
          onChange={handleChange}
        />
      </FormGroup>
    </>
  );
};
