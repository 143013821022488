import { StyledContainerLg } from '../UI/containers';
import {
  StyledFooter,
  StyledFooterGrid,
  StyledFooterGridItem,
  StyledFooterSocials,
  StyledFooterSocialsItem,
  StyledFooterTerms,
  StyledFooterTermsItem,
} from './styled';
import { ReactComponent as MailIcon } from '../../Static/svg/socials/mail.svg';
import { ReactComponent as InIcon } from '../../Static/svg/socials/in.svg';
import { ReactComponent as FacebookIcon } from '../../Static/svg/socials/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../Static/svg/socials/instagram.svg';
import { ReactComponent as WebIcon } from '../../Static/svg/socials/web.svg';
import { ReactComponent as PhoneIcon } from '../../Static/svg/socials/phone.svg';
import { ReactComponent as LocationIcon } from '../../Static/svg/socials/location.svg';
import L from './locale.json';
import { useLang } from '../../Hooks/useLang';
import { socialsConfig } from '../../Configs/socialsConfig';
import { openModal } from '../../Redux/Actions/ModalActions';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const { instagram, linkedIn, facebook, mail } = socialsConfig;

const getLnkPillIcon = (type) => {
  switch (type) {
    case 'phone':
      return <PhoneIcon />;
    case 'facebook':
      return <FacebookIcon />;
    case 'linkedIn':
      return <InIcon />;
    case 'instagram':
      return <InstagramIcon />;
    case 'web':
      return <WebIcon />;
    case 'map':
      return <LocationIcon />;
    default:
      return <MailIcon />;
  }
};

const getTarget = (type) => {
  switch (type) {
    case 'phone':
    case 'mail':
      return {};
    default:
      return { target: '_blank', rel: 'noreferrer noopener' };
  }
};

export const LinkPill = ({ type = 'mail', text, href }) => {
  return (
    <StyledFooterSocialsItem href={href} {...getTarget(type)}>
      {getLnkPillIcon(type)}
      <span>{text}</span>
    </StyledFooterSocialsItem>
  );
};

export const Footer = () => {
  const { copyright, termsConditions, faq } = useLang(L);
  const dispatch = useDispatch();

  const handleOpenModalFaq = () => {
    dispatch(
      openModal({
        Comp: 'modalFaq',
        preserve: true,
      })
    );
  };

  return (
    <StyledFooter>
      <StyledContainerLg>
        <StyledFooterGrid>
          <StyledFooterGridItem>{copyright}</StyledFooterGridItem>
          <StyledFooterGridItem>
            <StyledFooterSocials>
              <LinkPill type="mail" {...mail} />
            </StyledFooterSocials>
          </StyledFooterGridItem>
          <StyledFooterGridItem>
            <StyledFooterTerms>
              <StyledFooterTermsItem as={Link} to="/terms">
                {termsConditions}
              </StyledFooterTermsItem>
              <StyledFooterTermsItem onClick={handleOpenModalFaq}>
                {faq}
              </StyledFooterTermsItem>
            </StyledFooterTerms>
          </StyledFooterGridItem>
          <StyledFooterGridItem>
            <StyledFooterSocials>
              <LinkPill type="instagram" {...instagram} />
              <LinkPill type="facebook" {...facebook} />
              <LinkPill type="linkedIn" {...linkedIn} />
            </StyledFooterSocials>
          </StyledFooterGridItem>
        </StyledFooterGrid>
      </StyledContainerLg>
    </StyledFooter>
  );
};
