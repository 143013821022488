import styled from 'styled-components';
import S from '../UI/variables';

export const StyledNavbar = styled.nav`
  margin: 0 auto;

  @media (max-width: ${S.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledNavbarList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledNavbarListItem = styled.li`
  &:not(:last-child) {
    margin-right: 32px;
  }
`;

export const StyledNavbarListLink = styled.a`
  position: relative;
  display: block;
  color: ${S.colors.white};
  font-weight: 600;
  font-family: ${S.fontFamily.default};

  &::after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    background-color: ${S.colors.peach};
    transition:
      transform ${S.transitions.transform},
      opacity ${S.transitions.transform};
    transform: translateY(10px);
    opacity: 0;
  }

  &.is-active::after {
    transform: translateY(0);
    opacity: 1;
  }

  &:not(.is-active):hover::after {
    transform: translateY(0);
    opacity: 0.3;
  }
`;
