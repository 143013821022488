import emailjs from 'emailjs-com';

// TODO review config
export const emailConfig = {
  service_id: 'service_uhbzjm3',
  early_adopter_program_template_id: 'template_9ocn2rn',
  contact_us_template_id: 'template_idufzbq',
  user_id: 'ztk7-SmHnV6UeiikO',
};

export const sendMail = async (template_id, body) =>
  await emailjs.send(
    emailConfig.service_id,
    template_id,
    body,
    emailConfig.user_id
  );
