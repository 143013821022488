import styled from 'styled-components';
import { StyledContainerLg } from '../UI/containers';
import S from '../UI/variables';
import { StyledLogo } from '../Logo/styled';
import { rgba } from 'polished';
import { StyledAuthBtns } from '../AuthBtns/styled';

export const StyledHeaderInner = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledHeader = styled(StyledContainerLg)`
  position: sticky;
  top: 0;
  z-index: 2;
  padding: ${S.spaces.sm};
  margin-bottom: -76px;
  background-color: ${({ isActive }) =>
    isActive ? S.colors.purple : 'transparent'};
  transition: background-color ${S.transitions.background};

  & ${StyledAuthBtns} {
    @media screen and (max-width: ${S.breakpoints.lg}) {
      display: none;
    }
  }

  & ${StyledLogo} {
    width: 110px;
    margin-right: 16px;

    svg {
      width: 100%;
    }

    @media (min-width: ${S.breakpoints.df}) {
      width: 117px;
    }
  }
`;

export const StyledBurgerButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${rgba(S.colors.gray, 0.5)};
  border-radius: 15px;
  margin-left: 16px;
  position: relative;
  z-index: 20;
  cursor: pointer;
  transition: background-color ${S.transitions.background};

  &:hover {
    background-color: ${S.colors.purple};
  }

  .line-1,
  .line-2 {
    transition: transform ${S.transitions.transform};
    transform-origin: center center;
  }

  &.is-open {
    .line-1 {
      transform: translate(-3px, 3px) rotate(45deg);
    }

    .line-2 {
      transform: translate(-2px, -3px) rotate(-45deg);
    }
  }

  @media (min-width: ${S.breakpoints.lg}) {
    display: none;
  }
`;
