import { FormGroup } from '../../UI/Components/Form/FormGroup';
import { FormInput } from '../../UI/Components/Form/FormInput';
import { FormInputTelephone } from '../../UI/Components/Form/FormInputTelephone';

export const Step1 = ({ placeholders, errors, inputs, handleChange }) => {
  return (
    <>
      <FormGroup>
        <FormInput
          name="name"
          placeholder={placeholders.name}
          type="text"
          required
          error={errors.name}
          value={inputs.name || ''}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <FormInput
          name="surname"
          placeholder={placeholders.surname}
          type="text"
          required
          error={errors.surname}
          value={inputs.surname || ''}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <FormInputTelephone
          name="phone"
          required
          error={errors.phone}
          value={inputs.phone || ''}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <FormInput
          name="email"
          placeholder={placeholders.email}
          type="email"
          required
          error={errors.email}
          value={inputs.email || ''}
          onChange={handleChange}
        />
      </FormGroup>
    </>
  );
};
