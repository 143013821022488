import {
  StyledModalClose,
  StyledModalHeaderXs,
  StyledModalPopupXs,
} from '../styled';
import { ReactComponent as Close } from '../../../../../Static/svg/common/close-2.svg';

export const PopupXs = ({ children, onClose }) => {
  return (
    <StyledModalPopupXs>
      <StyledModalHeaderXs>
        <StyledModalClose onClick={onClose}>
          <Close />
        </StyledModalClose>
      </StyledModalHeaderXs>
      {children}
    </StyledModalPopupXs>
  );
};
