import {
  StyledModalCloseFull,
  StyledModalHeaderFull,
  StyledModalPopupFull,
} from '../styled';
import { StyledContainerLg } from '../../../containers';
import { Logo } from '../../../../Logo';
import { ReactComponent as CloseIcon } from '../../../../../Static/svg/common/close.svg';

export const PopupFull = ({ children, onClose }) => {
  return (
    <StyledModalPopupFull>
      <StyledModalHeaderFull>
        <StyledContainerLg>
          <Logo color="dark" />
          <StyledModalCloseFull onClick={onClose}>
            <CloseIcon />
          </StyledModalCloseFull>
        </StyledContainerLg>
      </StyledModalHeaderFull>
      {children}
    </StyledModalPopupFull>
  );
};
