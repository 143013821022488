import { WithSuspense } from '../HOC/withSuspense';
import { lazy } from 'react';

export const routes = [
  {
    path: '/',
    linkName: 'services',
    title: 'Services',
    Component: WithSuspense(lazy(() => import('../Pages/Services'))),
  },
  {
    path: '/become-partner',
    linkName: 'partners',
    title: 'Become our Partner',
    Component: WithSuspense(lazy(() => import('../Pages/BecomePartner'))),
  },
  {
    path: '/pricing',
    linkName: 'pricing',
    title: 'Pricing',
    Component: WithSuspense(lazy(() => import('../Pages/Pricing'))),
  },
  {
    path: '/terms',
    linkName: 'terms',
    skipNav: true,
    Component: WithSuspense(lazy(() => import('../Pages/Terms'))),
  },
  {
    path: '/',
    linkName: 'about',
    hash: '#about',
  },
  {
    path: '/',
    linkName: 'contact',
    hash: '#contact',
  },
];
