import DesktopImg from '../../Static/banners/partners/desktop.png';
import TabletImg from '../../Static/banners/partners/tablet.png';
import TabletLandscapeImg from '../../Static/banners/partners/tanlet-landscape.png';
import MobileImg from '../../Static/banners/partners/mobile.png';
import DesktopImgWebp from '../../Static/banners/partners/desktop.webp';
import TabletImgWebp from '../../Static/banners/partners/tablet.webp';
import TabletLandscapeImgWebp from '../../Static/banners/partners/tanlet-landscape.webp';
import MobileImgWebp from '../../Static/banners/partners/mobile.webp';
import { HeaderBanner } from '../../Components/HeaderBanner';

export const NotFound = () => {
  return (
    <HeaderBanner
      page="error"
      images={{
        xl: DesktopImg,
        md: TabletImg,
        df: TabletLandscapeImg,
        xs: MobileImg,
      }}
      imagesWebp={{
        xl: DesktopImgWebp,
        md: TabletImgWebp,
        df: TabletLandscapeImgWebp,
        xs: MobileImgWebp,
      }}
    />
  );
};
