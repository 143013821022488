import {
  StyledForm,
  StyledFormWrapper,
  StyledHeaderBanner,
  StyledHeaderBannerButton,
  StyledHeaderBannerImg,
  StyledHeaderBannerInner,
  StyledHeaderBannerRatio,
  StyledHeaderBannerText,
  StyledOr,
  StyledPhoneIcon,
  StyledPhoneLink,
} from './styled';
import { useLang } from '../../Hooks/useLang';
import L from './locale.json';
import { useSelector } from 'react-redux';
import { FormGroup } from '../UI/Components/Form/FormGroup';
import { FormInput } from '../UI/Components/Form/FormInput';
import { StyledButton } from '../UI/buttons';
import { useState } from 'react';
import { validation } from '../../Utils/validation';
import { getLang } from '../../Redux/Selectors/language';
import { BASE_URL } from '../../Configs/constants';
import { ReactComponent as PhoneIcon } from '../../Static/svg/common/phone.svg';

const VehicleForm = () => {
  const lang = useSelector(getLang);
  const locale = useLang(L);
  const [inputs, setInputs] = useState({ vin: '', number: '' });

  const handleChange = (event) => {
    const { value } = validation(event.target, lang);
    console.log('change');
    setInputs({ ...inputs, [event.target.name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const a = document.createElement('a');
    a.href = `${BASE_URL}/onboarding?vin=${inputs.vin}&number=${inputs.number}`;
    a.click();
  };

  return (
    <StyledFormWrapper>
      <StyledForm as="div">
        <StyledPhoneLink href="tel:37060419653">
          <StyledPhoneIcon>
            <PhoneIcon />
          </StyledPhoneIcon>
          <span>{locale.vehicle.callUs}</span>
        </StyledPhoneLink>
      </StyledForm>
      <StyledOr>{locale.vehicle.or}</StyledOr>
      <StyledForm onSubmit={onSubmit}>
        <FormGroup>
          <FormInput
            label={locale.vehicle.vinTitle}
            type="text"
            placeholder={locale.vehicle.vinPlaceholder}
            name="vin"
            value={inputs.vin || ''}
            required
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <FormInput
            label={locale.vehicle.plateTitle}
            type="text"
            placeholder={locale.vehicle.platePlaceholder}
            name="number"
            value={inputs.number || ''}
            required
            onChange={handleChange}
          />
        </FormGroup>
        <StyledButton type="submit" disabled={!inputs.vin || !inputs.number}>
          {locale.vehicle.submit}
        </StyledButton>
      </StyledForm>
    </StyledFormWrapper>
  );
};

export const HeaderBanner = ({ page, images, imagesWebp }) => {
  const locale = useLang(L);
  //const dispatch = useDispatch();

  //   const handleOpenModal = () => {
  //     dispatch(
  //       openModal({
  //         Comp: 'modalSubmitYourApp',
  //         preserve: false,
  //       })
  //     );
  //   };

  return (
    <StyledHeaderBanner as="div">
      <StyledHeaderBannerRatio>
        <picture>
          {images.xs ? (
            <source srcSet={images.xs} media="(max-width: 480px)" />
          ) : null}
          {imagesWebp.xs ? (
            <source
              type="image/webp"
              srcSet={imagesWebp.xs}
              media="(max-width: 480px)"
            />
          ) : null}
          {images.md ? (
            <source srcSet={images.md} media="(max-width: 768px)" />
          ) : null}
          {imagesWebp.md ? (
            <source
              type="image/webp"
              srcSet={imagesWebp.md}
              media="(max-width: 768px)"
            />
          ) : null}
          {images.df ? (
            <source srcSet={images.md} media="(max-width: 1024px)" />
          ) : null}
          {imagesWebp.df ? (
            <source
              type="image/webp"
              srcSet={imagesWebp.df}
              media="(max-width: 1024px)"
            />
          ) : null}
          {imagesWebp.xl ? (
            <source type="image/webp" srcSet={imagesWebp.df} />
          ) : null}
          {images.xl ? (
            <StyledHeaderBannerImg src={images.xl} isError={page === 'error'} />
          ) : null}
        </picture>
        <StyledHeaderBannerInner>
          {locale[page]['title'] ? (
            <StyledHeaderBannerText>
              {locale[page]['title']}
            </StyledHeaderBannerText>
          ) : (
            ''
          )}
          {page === 'partners' ? (
            <StyledHeaderBannerButton
              as="a"
              href={`${BASE_URL}/registration`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {locale[page]['button']}
            </StyledHeaderBannerButton>
          ) : null}
          {page === 'services' ? <VehicleForm /> : null}
        </StyledHeaderBannerInner>
      </StyledHeaderBannerRatio>
    </StyledHeaderBanner>
  );
};
