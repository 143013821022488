import { PopupXs } from '../PopupXs';
import { Faq } from '../../../../Faq';
import { StyledModalTitleXS } from '../styled';
import L from '../../../../Faq/locale.json';
import { useLang } from '../../../../../Hooks/useLang';

export const ModalFaq = ({ onClose }) => {
  const { name } = useLang(L);
  return (
    <>
      <PopupXs onClose={onClose}>
        <StyledModalTitleXS>{name}</StyledModalTitleXS>
        <Faq />
      </PopupXs>
    </>
  );
};
