import styled from 'styled-components';
import S from '../UI/variables';
import { rgba } from 'polished';

export const StyledLanguagePicker = styled.ul`
  display: flex;
  margin-left: auto;

  @media (min-width: ${S.breakpoints.lg}) {
    margin-left: 0;
  }
`;

export const StyledLanguagePickerItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 14px;
  height: 40px;
  width: 32px;
  background-color: ${rgba(S.colors.gray, 0.5)};
  border-radius: 15px;
  cursor: pointer;
  text-transform: uppercase;
  transition:
    color ${S.transitions.color},
    background-color ${S.transitions.background};

  @media (min-width: ${S.breakpoints.lg}) {
    font-size: 16px;
    line-height: 20px;
  }

  &:hover,
  &.is-active:hover {
    color: ${S.colors.white};

    @media (min-width: ${S.breakpoints.lg}) {
      background-color: ${S.colors.purple};
    }
  }

  &:not(:last-child) {
    margin: 0 4px 0 0;
  }

  @media (min-width: ${S.breakpoints.lg}) {
    height: 44px;
    width: 38px;
  }

  &.is-active {
    color: ${S.colors.white};
    font-weight: 600;
  }
`;
