import { useDispatch, useSelector } from 'react-redux';
import { getLang } from '../../Redux/Selectors/language';
import { setLanguage } from '../../Redux/Actions/langeugesActions';
import { StyledLanguagePicker, StyledLanguagePickerItem } from './styled';
import { langs } from '../../Configs/constants';

export const LanguagePicker = () => {
  const dispatch = useDispatch();
  const lang = useSelector(getLang);

  const switchLang = (lang) => () => {
    dispatch(setLanguage(lang));
  };

  return (
    <StyledLanguagePicker>
      {langs.map((el) => {
        return (
          <StyledLanguagePickerItem
            onClick={switchLang(el)}
            key={el}
            className={lang === el ? 'is-active' : ''}
          >
            {el}
          </StyledLanguagePickerItem>
        );
      })}
    </StyledLanguagePicker>
  );
};
