const S = {
  colors: {
    purple: '#AE9DF4',
    purpleDark: '#8376BA',
    green: '#1A434E',
    greenLight: '#C3F44D',
    black: '#262626',
    white: '#FFFFFF',
    pink: '#FFBCD8',
    blue: '#BCEBFF',
    gray: '#F6F6F6',
    gray2: '#C4C1D2',
    gray3: '#D6D6D6',
    gray4: '#DFDFDF',
    gray5: '#EEEEEE',
    peach: '#FF6B6B',
  },
  transitions: {
    color: '.3s ease',
    background: '.2s ease',
    transform: '.4s ease',
  },
  fontFamily: {
    default: "'Quicksand', sans-serif",
    defaultRu: "'Raleway', sans-serif",
    additional: "'Playfair Display', sans-serif",
  },
  containers: {
    xxs: '355px',
    xs: '800px',
    sm: '820px',
    md: '920px',
    df: '1000px',
    lg: '1280px',
  },
  spaces: {
    xxs: '8px',
    xs: '12px',
    sm: '16px',
    md: '26px',
    df: '32px',
    lg: '48px',
    xl: '70px',
  },
  breakpoints: {
    xxs: '375px',
    xs: '480px',
    sm: '640px',
    md_small: '720px',
    md: '768px',
    df: '1024px',
    lg: '1280px',
    xxl: '1440px',
  },
};

export default S;
