import { useEffect, useMemo, useRef } from 'react';
import {
  StyledFaqItem,
  StyledFaqItemArrow,
  StyledFaqItemBody,
  StyledFaqItemHeader,
  StyledFaqItemText,
  StyledFaqItemTitle,
} from '../styled';
import { ReactComponent as Arrow } from '../../../Static/svg/common/chevron.svg';
import { socialsConfig } from '../../../Configs/socialsConfig';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../Redux/Actions/ModalActions';

export const FaqItem = ({
  indexItem,
  activeItem,
  onOpen,
  question,
  answer,
}) => {
  const refBody = useRef(null);
  const refText = useRef(null);
  const dispatch = useDispatch();

  const closeModalFaq = () => {
    dispatch(closeModal({}));
  };

  const strings = useMemo(() => {
    return answer.split(/{(?<href>{\w+})}/g);
  }, [answer]);

  useEffect(() => {
    activeItem === indexItem
      ? (refBody.current.style.maxHeight = refText.current.scrollHeight + 'px')
      : (refBody.current.style.maxHeight = 0);
  }, [activeItem, indexItem]);

  useEffect(() => {
    refBody.current.style.maxHeight = 0;
  }, []);

  return (
    <StyledFaqItem className={activeItem === indexItem ? 'is-open' : ''}>
      <StyledFaqItemHeader onClick={onOpen}>
        <StyledFaqItemTitle>{question}</StyledFaqItemTitle>
        <StyledFaqItemArrow as={Arrow} />
      </StyledFaqItemHeader>
      <StyledFaqItemBody ref={refBody}>
        <StyledFaqItemText ref={refText}>
          {strings.map((str) => {
            const link = (str.match(/{(?<href>\w+)}/) || []).groups?.href;

            if (link) {
              const { navLink, href, text } = socialsConfig[link];

              return navLink ? (
                <Link className="link" to={navLink} onClick={closeModalFaq}>
                  {text}
                </Link>
              ) : (
                <a
                  className="link"
                  href={href}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {text}
                </a>
              );
            } else {
              return str;
            }
          })}
        </StyledFaqItemText>
      </StyledFaqItemBody>
    </StyledFaqItem>
  );
};
