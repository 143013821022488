import S from '../../variables';
import styled from 'styled-components';

export const StyledForm = styled.form`
  button {
    width: 100%;
  }
`;

export const StyledFormGroup = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const StyledFormTelephone = styled.div`
  position: relative;
`;

export const StyledFormTelephoneLabel = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 16px;
  border-right: 1px solid ${S.colors.gray2};
  color: ${S.colors.gray2};
`;

export const StyledFormHint = styled.div`
  position: absolute;
  font-size: 12px;
  line-height: 15px;
  z-index: 1;
  top: 50%;
  right: 4px;
  max-width: 140px;
  transform: translateY(-50%);
  padding: 10px;
  background-color: #cfe8ff;
  border-radius: 16px;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &::before {
    content: '';
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 10px solid #cfe8ff;
    left: -7px;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (min-width: ${S.breakpoints.df}) {
    right: auto;
    left: calc(100% + 2px);
  }
`;

export const StyledFormInput = styled.input`
  display: block;
  appearance: none;
  box-shadow: none;
  outline: none;
  border: none;
  line-height: 48px;
  font-size: 20px;
  padding: 0 16px;
  border-radius: 15px;
  background-color: ${S.colors.white};
  transition:
    box-shadow ${S.transitions.transform},
    opacity ${S.transitions.transform};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: ${S.breakpoints.md}) {
    line-height: 56px;
  }

  ${StyledFormTelephoneLabel} + & {
    padding-left: 86px;
  }

  &.has-error {
    box-shadow: 0 0 0 1px red;
  }

  &.has-hint {
    @media (max-width: ${S.breakpoints.df}) {
      padding-right: 160px;
    }
  }

  &:not(.has-error).has-value {
    box-shadow: 0 0 0 1px ${S.colors.purpleDark};
  }

  &:hover:not(.has-error):not(:disabled):not(:focus) {
    box-shadow: 0 0 0 1px ${S.colors.purpleDark};
  }

  &:focus:not(.has-error) {
    box-shadow: 0 0 0 1px ${S.colors.purpleDark};
  }

  &[disabled]:not([readonly]) {
    opacity: 0.65;
  }

  &::placeholder {
    color: ${S.colors.gray2};
  }
`;

export const StyledFormTextarea = styled(StyledFormInput)`
  border-radius: 8px;
  padding: 16px;
  line-height: 20px;
  resize: none;
  height: 100px;

  @media (max-width: ${S.breakpoints.md}) {
    padding: 20px 16px;
  }
`;

export const StyledFormCheckBox = styled.div`
  cursor: pointer;
  display: inline-flex;
  vertical-align: top;
  font-size: 14px;
  line-height: 18px;
  position: relative;
  user-select: none;
  padding-bottom: 4px;

  input {
    position: absolute;
    opacity: 0;
    height: 1px;
    width: 1px;

    &:checked ~ span {
      &::before {
        transform: scale(1);
        opacity: 1;
        border-color: ${S.colors.greenLight};
      }
    }

    &:focus-visible ~ span {
      &::after {
        border-color: ${S.colors.greenLight};
        opacity: 0.5;
      }

      &::before {
        border-color: ${S.colors.greenLight};
        background-color: ${S.colors.greenLight};
        transform: scale(0.5);
        opacity: 0.5;
      }
    }

    &[disabled],
    &[readonly] {
      ~ span {
        cursor: default;
        user-select: none;
      }
    }

    &[disabled]:not([readonly]) ~ span {
      color: ${S.colors.gray2};

      &::before {
        border-color: ${S.colors.gray2};
        background-color: ${S.colors.gray2};
      }

      &::after {
        border-color: ${S.colors.gray2};
      }
    }

    &.has-error {
      ~ span {
        &::before {
          background: ${S.colors.purpleDark};
          border-color: ${S.colors.purpleDark};
        }
      }
    }
  }

  span {
    display: flex;
    align-items: flex-start;
    color: ${S.colors.purpleDark};
    line-height: 21px;
    transition: color ${S.transitions.color};

    .contact-us-form & {
      color: ${S.colors.white};
    }

    &::before {
      content: '';
      transform: scale(0);
      opacity: 0;
      background: ${S.colors.greenLight}
        url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 2.84615L5.09091 7L11 1' stroke='%231A434E' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A")
        no-repeat center center;
      border-radius: 4px;
      border: 1px solid ${S.colors.greenLight};
      flex-shrink: 0;
      margin-right: 8px;
      height: 20px;
      width: 20px;
      position: relative;
      transition:
        border ${S.transitions.color},
        transform ${S.transitions.transform},
        opacity ${S.transitions.transform},
        background ${S.transitions.background};
      z-index: 1;
      transform-origin: center center;
    }

    &::after {
      content: '';
      border-radius: 4px;
      border: 1px solid ${S.colors.greenLight};
      height: 20px;
      width: 20px;
      position: absolute;
    }

    a {
      text-decoration: none;
    }
  }

  &:hover {
    input:not([disabled]):not([readonly]) {
      ~ span {
        color: ${S.colors.greenLight};

        &::before {
          border-color: ${S.colors.greenLight};
        }
      }
    }
  }
`;

export const StyledFormError = styled.span`
  font-size: 10px;
  color: red;
`;
