import styled from 'styled-components';
import S from '../UI/variables';

export const StyledNavbarPanel = styled.nav`
  position: relative;
  z-index: 1;
`;

export const StyledNavbarPanelList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledNavbarPanelListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const StyledNavbarPanelListLink = styled.a`
  display: block;
  color: ${S.colors.white};
  font-weight: 600;
  font-family: ${S.fontFamily.additional};
  font-size: 32px;
  line-height: 38px;

  &:not(.is-anchor).active {
    color: ${S.colors.purple};
  }
`;
