import { EarlyAdopterProgram } from '../../../../EarlyAdopterProgram';
import { PopupFull } from '../PopupFull';
import { ReactComponent as Ball } from '../../../../../Static/svg/common/ball.svg';
import { ReactComponent as Like } from '../../../../../Static/svg/common/like.svg';

export const ModalSubmitYourApp = ({ onClose }) => {
  return (
    <>
      <PopupFull onClose={onClose}>
        <span>
          <Ball />
          <Like />
        </span>
        <EarlyAdopterProgram />
      </PopupFull>
    </>
  );
};
