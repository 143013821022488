import styled from 'styled-components';
import S from './variables';

export const StyledButton = styled.button`
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  padding: 20px 16px;
  border-radius: 15px;
  color: ${S.colors.green};
  background-color: ${S.colors.greenLight};
  cursor: pointer;
  transition:
    color ${S.transitions.color},
    background-color ${S.transitions.background},
    opacity ${S.transitions.background};

  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }

  @media (min-width: ${S.breakpoints.md}) {
    padding: 20px 62px;
  }

  &:hover {
    background-color: ${S.colors.green};
    color: ${S.colors.white};
  }
`;
