import styled from 'styled-components';
import S from '../UI/variables';

export const StyledLoading = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  line-height: 1;
  background-color: ${S.colors.purple};
  color: ${S.colors.white};
  font-weight: 700;

  @media (min-width: ${S.breakpoints.md}) {
    font-size: 50px;
  }
`;
