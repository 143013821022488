import { NavbarPanel } from '../NavbarPanel';
import {
  StyledMobilePanel,
  StyledMobilePanelBg,
  StyledMobilePanelFooter,
  StyledMobilePanelScroll,
} from './styled';
import { ReactComponent as Bg } from '../../Static/modal/bg-mobile-panel.svg';
import { AuthBtns } from '../AuthBtns';

export const MobilePanel = ({ open, onClose }) => {
  return (
    <StyledMobilePanel open={open}>
      <StyledMobilePanelBg as={Bg} />
      <StyledMobilePanelScroll>
        <NavbarPanel onClose={onClose} />
      </StyledMobilePanelScroll>
      <StyledMobilePanelFooter>
        <AuthBtns />
      </StyledMobilePanelFooter>
    </StyledMobilePanel>
  );
};
